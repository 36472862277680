import React from "react";

import { BlockWrapper, IntroBlock } from "components/Block";

import { Inner, Text } from "./styles";

const Develop = ({ content }) => {
  return (
    <BlockWrapper
      shade="lightgray"
      pt={["50px", "50px", "60px"]}
      pb={["40px", "40px", "50px"]}
      px={["0", "0", "0", "10px"]}
    >
      <IntroBlock content={content} shade="dark" />
      <Inner>
        <Text>{content.text}</Text>
      </Inner>
    </BlockWrapper>
  );
};

export default Develop;
