import styled from "styled-components";
import { transparentize } from "polished";

import theme from "config/theme";

import { H4 } from "components/Text";

export const Inner = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 40px;

  @media (max-width: ${theme.size.tablet}) {
    flex-wrap: wrap;
  }
`;

export const Feature = styled.div`
  width: calc(33% - 10px);
  margin: 10px 10px 0;
  padding: 35px 30px;
  text-align: center;
  border: 1px solid ${transparentize(0.7, theme.color.gray2)};

  @media (max-width: ${theme.size.desktop}) {
    width: calc(33% - 6px);
    margin: 0 3px;
    padding: 35px 25px;
  }

  @media (max-width: ${theme.size.tablet}) {
    width: 100%;
    margin: 5px 0;
  }
`;

export const Heading = styled(H4)`
  color: ${theme.color.dark};
  margin-bottom: 15px;
`;

export const Text = styled.div`
  color: ${theme.color.dark};
  font-size: ${theme.font.size.delta};
`;
