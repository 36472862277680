import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "components/Layout";
import { HeroFeature } from "components/Hero";
import { ContentBlock, IntroBlock } from "components/Block";

// Imported to format the block1
// see line: <Develop content={page.block1} />
import Develop from "templates/home-page/Develop";
import DevelopFeature from "templates/home-page/DevelopFeature";

export const HomePageTemplate = ({ page, markdown }) => {
  return (
    <>
      <HeroFeature content={page.hero} />
      <Develop content={page.block1} />
      <ContentBlock
        flip
        pt={["50px", "60px", "130px"]}
        pb={["60px", "70px", "100px"]}
        content={page.block2}
        contentShade="dark"
        imageSize="small"
        size="large"
      />
      <ContentBlock
        flip
        pt={["50px", "60px", "130px"]}
        pb={["60px", "70px", "100px"]}
        content={page.block3}
        contentShade="dark"
        imageSize="small"
        size="large"
      />
      <DevelopFeature content={page.block4} />
      <IntroBlock
        pt={["50px", "60px", "130px"]}
        pb={["60px", "70px", "100px"]}
        content={page.cta}
        contentShade="dark"
        imageSize="small"
        size="large"
      />
    </>
  );
};

const HomePage = ({ data }) => {
  const { frontmatter: page } = data.allMarkdownRemark.edges[0].node;
  return (
    <Layout page={page} shade="light" headerShade="light" footerShade="gray77">
      <HomePageTemplate page={page} />
    </Layout>
  );
};

HomePage.propTypes = {
  data: PropTypes.object.isRequired
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            page {
              title
            }
            hero {
              heading
              subheading
            }
            block1 {
              title
              text
            }
            block2 {
              title
              heading
              text
              buttonText
              buttonPath
              image {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            block3 {
              title
              heading
              text
              buttonText
              buttonPath
              image {
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 100) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            block4 {
              title
              heading
              subheading
              features {
                heading
                text
                buttonText
                buttonPath
              }
            }
            cta {
              title
              heading
              subheading
              buttonText
              buttonPath
            }
          }
        }
      }
    }
  }
`;
