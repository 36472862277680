import React from "react";

import Button from "components/Button";
import { BlockWrapper, IntroBlock } from "components/Block";

import { Inner, Feature, Heading, Text } from "./styles";

const DevelopFeatuer = ({ content }) => {
  return (
    <BlockWrapper
      shade="lightgray"
      pt={["50px", "50px", "60px"]}
      pb={["40px", "40px", "50px"]}
      px={["0", "0", "0", "10px"]}
    >
      <IntroBlock content={content} shade="medium" />
      <Inner>
        {content.features.map((feature, index) => (
          <Feature key={index}>
            <Heading>{feature.heading}</Heading>
            <Text>{feature.text}</Text>
            {feature.buttonText && feature.buttonPath && (
              <Button
                to={feature.buttonPath}
                mt="35px"
                shade="primary"
                size="small"
              >
                {feature.buttonText}
              </Button>
            )}
          </Feature>
        ))}
      </Inner>
    </BlockWrapper>
  );
};

export default DevelopFeatuer;
